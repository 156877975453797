//LOGOS CAVEPOT
export const LogoHeader = require('../assets/img/we-love-code.png').default;
export const LogoFooter = require('../assets/img/logo-footer.png').default;
export const CavepotLabLogo = require('../assets/img/cavepot-lab-logo.png').default;
export const LogoCPBlanco = require('../assets/img/logoCPBlanco.png').default;
export const LogoCPNegro = require('../assets/img/logoCPNegro.png').default;
export const LogoblancoColor = require('../assets/img/logoblancoColor.png').default;


//SIMBOLOS
export const RightArrow = require('../assets/img/right-arrow.png').default;
export const LeftArrow = require('../assets/img/left-arrow.png').default;
export const Shortblueline = require('../assets/img/shortblueline.png').default;
export const Olla = require('../assets/img/olla.png').default;

//REDES SOCIALES
export const Redes = require('../assets/img/redessociales.png').default;

//BANNERS
export const Welcome = require('../assets/img/welcome.png').default;
export const Laboratory = require('../assets/img/laboratory.png').default;
export const exploreLeft = require('../assets/img/exploreLeft.png').default;
export const exploreRight = require('../assets/img/exploreRight.png').default;
export const woman = require('../assets/img/woman.png').default;

//IMG GENERALES
export const AboutUsRed = require('../assets/img/red.png').default;
export const AboutUsYellow = require('../assets/img/yellow.png').default;
export const AboutUsPurple = require('../assets/img/purple.png').default;
export const create = require('../assets/img/create.png').default;
export const learn = require('../assets/img/learn.png').default;
export const disrupt = require('../assets/img/disrupt.png').default;