import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    text: "Be curious. Read widely. Try new things. What people call intelligence just boils down to curiosity.",
    firm: "Aaron Swartz"
  },
  {
    id: uuidv4(),
    text: "Talent wins games, but teamwork and intelligence win championships",
    firm: "Michael Jordan"
  }
];

export default dataSlider;